import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function ChatBotDocs() {
  return (
    <Layout
      keywords="IrisGPT documentation"
      title="Set up IrisGPT AI chatbot on your website or help center page"
      description="Documentation on how to integrate IrisAgent AI chatbot on your website or help center page"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/chatbot-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Documentation for IrisAgent's Chatbot</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>ChatBot</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>Integrating IrisAgent's Chatbot on your website</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>
                    IrisAgent's customizable chatbot can be deployed on any website to answer support questions. This chatbot is designed to enhance customer support and provide instant assistance to website visitors. Here are the key features and capabilities:
                    <br/><br/>
                    <ol>
                    <li>
                    <b>Customizability</b>: The chatbot can be fully customized to match your brand's identity. You can customize the chatbot's appearance, including its colors, fonts, and overall design, to seamlessly integrate it into your website.
                    </li>
                    <li>
                    <b>Branding and Logo</b>: You have the ability to add your brand's logo to the chatbot interface, ensuring a consistent visual representation of your company's identity throughout the customer experience.
                    </li>
                    <li>
                    <b>Welcome Message</b>: You can create a personalized welcome message that will greet visitors as soon as they initiate a conversation with the chatbot. This message can be tailored to introduce your brand, set expectations, and guide users on how to get the support they need.
                    </li>
                    <li>
                    <b>Support Question Handling</b>: The chatbot is equipped to handle a wide range of support questions and provide relevant answers in real-time. It utilizes natural language processing (NLP) and machine learning techniques to understand user inquiries and respond accurately.
                    </li>
                    <li>
                    <b>Analytics and Insights</b>: The chatbot provides detailed analytics and insights into user interactions, allowing you to understand customer behavior, identify frequently asked questions, and optimize the chatbot's performance over time.
                    </li>
                    </ol>
                    <br/>
                    <h2>
                    Getting Started and Integration Guide
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Request this feature by contacting our sales team. Once enabled, you will see a <b>Get Started</b> page under <b>IrisGPT</b> to help you configure and embed this chatbot.
                    </li>

<br/><img
                src="/img/chatbot.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>


                    <li>
                    Once you have the <b>UNIQUE_IDENTIFIED</b> provided by our team, plug its value in the below code snippet and paste it in the <b>body</b> section of your website.

                    <SyntaxHighlighter language="javascript" style={prism}>
  {`

  <script src="https://frontend-api-server-v2.api.irisagent.com/v1/chatbot/bundle"></script>
  <script type='text/javascript'>
    initIrisAgentChatbot( "UNIQUE_IDENTIFIER" );
  </script>
  `}
</SyntaxHighlighter>
                    </li>
                    <br/>
                    <li>
                    You can customize the bot logo, brand color, etc. on the same <b>"Get Started"</b> page.
                    </li>
                    <li>
                    If you want to customize the welcome and agent handoff message, please <a href="mailto:contact@irisagent.com" target="_blank">email us</a> the respective messages. Note that the agent handoff message typically contains instructions to contact the support team.
                    </li>
                    <li>
                    Ticketing integration: If you want to open a ticket or connect to live agent via the chatbot, please <a href="mailto:contact@irisagent.com" target="_blank">contact us</a> to sign up for the chatbot integration module.
                    </li>
                    <li>
                    You can see all the conversations that happened on the chatbot on the <b>"Conversations"</b> page under "IrisGPT" <a href="https://web.irisagent.com/chatbot-activity" target="_blank">here</a>.
                    </li>
                    <li>
                    That’s it, done! You should be seeing the IrisAgent chatbot on your website.
                    </li>
                    </ol>





                    </div>
                    </div>
                    </section>

</Layout>
  )
}
